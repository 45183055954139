import React from 'react';

import * as Styled from './style';

const HomeLink = () => (
  <span role="img" aria-label="peace-sign-hand">
    <Styled.HomeLink to="/">✌️</Styled.HomeLink>
  </span>
);

export default HomeLink;