import React from 'react';
import { Link } from 'gatsby'; 

import * as Styled from './style';

const Footer = () => (
  <Styled.Footer>
    <Link to="/#writing" title="Writing">
      Writing
    </Link>
    <Link to="/#work" title="Work">
      Work
    </Link>
    <Link to="/#contact" title="Contact">
      Contact
    </Link>
  </Styled.Footer>
);

export default Footer;

