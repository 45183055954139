import React from 'react';
import { Link } from 'gatsby';

import HomeLink from './homeLink';
import ColorModeButton from './colorModeButton';
import * as Styled from './style';

const Nav = ({ pathname }: { pathname: string }) => {
  const isHome = pathname === '/';

  return(
    <Styled.Nav isHome={isHome}>
      <HomeLink />
      {!isHome && (
        <Styled.BreadCrumbs>
          {extractPageCategory(pathname)}
        </Styled.BreadCrumbs>
      )}
      <ColorModeButton />
    </Styled.Nav>
  );
};

const extractPageCategory = (pathname: string) => {
  const pageCategory = pathname.split('/')[1];

  return <Link to={`/${pageCategory}/`}>/{pageCategory}</Link>;
};

export default Nav;