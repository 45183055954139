import styled from '@emotion/styled';

import CustomTheme from 'interfaces/CustomTheme';
import { SPACER } from 'consts';

export const Container = styled.div`
  display: flex; 
  justify-content: flex-end;
  align-items: center;
`;

export const Button = styled.button<{ theme: CustomTheme }>`
  cursor: pointer;
  width: 5rem;
  border: 0;
  padding: ${SPACER.small} ${SPACER.base};

  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};

  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: 16px;
`;