import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

export const Nav = styled.nav<{ isHome: boolean; theme: CustomTheme; }>`
  height: ${SPACER.x4large};
  border: 1px solid;
  border-bottom: 1px dashed;
  border-left: 1px solid transparent; 
  border-right: 1px solid transparent;
  padding: 0 ${SPACER.small};

  display: grid;
  grid-template-columns: 1fr minmax(auto, 1280px) 1fr;
  align-items: center;
  z-index: 1;
  
  position: fixed; 
  top: 0; 
  left: 0; 
  right: 0;
  background-color: ${({ theme }) => theme.colors.background};

  ${({ isHome }) => isHome && `
    border-color: unset;
    border-bottom: 1px solid;
    grid-template-columns: 1fr 1fr; 
    transition: background-color .25s ease-out;
  `}
`;

export const BreadCrumbs = styled.div<{ theme: CustomTheme }>`
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: 16px;
  padding: 0 ${SPACER.base};
`;