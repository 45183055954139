import React from 'react';

import Nav from 'components/nav';
import Footer from 'components/footer';
// @ts-ignore
import SEO from 'components/seo.js';

import './layout.css';

// @ts-ignore
const Layout = ({ seo, location, children }) => {
  const { pathname } = location; 
  const isHome = pathname === '/';

  return(
    <>
      <SEO {...seo}/>
      <Nav pathname={pathname}/>
      <main>
        {children}
      </main>
      {!isHome && <Footer />}
    </>
  );
};

export default Layout; 