import React from 'react';
import { useColorMode } from 'theme-ui';

import * as Styled from './style';

const MODES = [
  'light',
  'dark',
  'times',
];

const ColorModeButton = () => {
  const [mode, setMode] = useColorMode();

  return(
    <Styled.Container>
      <Styled.Button
        onClick={() => {
          const index = MODES.indexOf(mode);
          const next = MODES[(index + 1) % MODES.length];
          setMode(next);
        }}
      >
        {mode}
      </Styled.Button>
    </Styled.Container>
  );
};

export default ColorModeButton;