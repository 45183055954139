import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

export const Footer = styled.footer<{ theme: CustomTheme }>`
  border-top: 1px dashed;
  border-bottom: 1px solid;
  height: ${SPACER.x4large};
  padding: 0 ${SPACER.base};
  display: flex; 
  justify-content: space-between;
  align-items: center;

  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 20px; 
  font-style: italic;
`;