import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { SPACER } from 'consts';

export const HomeLink = styled(Link)`
  font-size: 24px;
  padding: ${SPACER.small};

  &:hover {
    text-decoration: none;
  }
`;